.resume__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.resume__link {
	color: var(--main-text-color);
}

.resume {
	background: var(--main-bg-color);
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: auto;
}

.resume--dark {
	background: var(--main-bg-color--dark);
}

.resume__title {
	color: var(--main-title-color);
}

.resume__title--dark {
	color: var(--main-title-color--dark);
}

.resume__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.resume__left-container, .resume__right-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 250px;
}

.resume__left-container {
	flex-grow: 2;
}

@media (min-width: 640px) {
	.resume {
		padding: 50px;
	}

	.resume__left-container {
		margin-right: 20px;
	}

	.resume__right-container {
		margin-left: 20px;
	}
}
