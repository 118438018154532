/* palette */
:root {
	--independence: #3a405a;
	--champagne-pink: #f9dec9;
	--polished-pine: #519e8a;
	--green-sheen: #68b0ab;
	--white: #fff;
	--light-grey: #767d9e;
}

/* apply palette */
:root {
	--main-bg-color: var(--white);
	--main-bg-color--dark: var(--independence);
	--main-text-color: var(--independence);
	--main-text-color--dark: var(--champagne-pink);
	--main-title-color: var(--polished-pine);
	--main-title-color--dark: var(--champagne-pink);
	--main-accent-color: var(--polished-pine);
	--main-accent-color--dark: var(--green-sheen);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
	margin: 0;
	padding: 0;
	height: 100%;
}
