.resume__resume-section__title {
	color: var(--main-text-color);
	border-bottom: 3px var(--main-accent-color) solid;
	width: fit-content;
}

.resume__resume-section__title--dark {
	color: var(--main-text-color--dark);
}

.resume__resume-section__content {
	color: var(--main-text-color);
}

.resume__resume-section__content--dark {
	color: var(--main-text-color--dark);
}
